import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './styles.scss'

const SearchResultsPageList = ({ variant, itemsRepeater }) => {
    return (
        <ul
            className={`c-search-results-page-list c-search-results-page-list--${variant}`}
        >
            {itemsRepeater.map((node) => {
                return (
                    <li key={node.id}>
                        <Link to={node.uri}>{node.title}</Link>
                    </li>
                )
            })}
        </ul>
    )
}

SearchResultsPageList.propTypes = {
    variant: PropTypes.oneOf(['standard', 'columns']),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            uri: PropTypes.string
        })
    ).isRequired
}

SearchResultsPageList.defaultProps = {
    variant: 'standard',
    itemsRepeater: []
}

export default SearchResultsPageList
